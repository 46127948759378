* {box-sizing: border-box;}

.App {
	max-width: 1400px;
	margin: 6rem auto;
	padding: 1rem;
	.createQR {
		display: flex;
		justify-content: space-between;
		margin-bottom: 6rem;
		flex-wrap: wrap;
		h2 {width: 100%;}
		.formQR {
			width: calc(30% - 1rem);
			input {
				width: 100%;
				margin-bottom: 1rem;
				display: block;
				padding: 1rem;
			}
			button {padding: 1rem;}
			label {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 10px;
				input {
					width: fit-content;
					margin-bottom: 0;
					margin-top: 0;
					margin-right: 1rem;
				}
			}
		}
		.tableQR {
			width: calc(70% - 1rem);
			table {
				width: 100%;
				border-collapse: collapse;
				th, td {border: 1px solid black;}
				tbody {
					td {
						text-align: center;
						padding: 1rem;
						svg {
							width: 60%;
							height: fit-content;
							min-width: 100px;
							max-width: 150px;
						}
					}
				}
			}
		}
		@media screen and (max-width: 768px) {
			.form, .table {width: 100%!important;}
			.table {margin-top: 2rem;}
		}
	}
}


.formCreateUser, .formLogin {
	margin-top: 3rem;
	label {
		display: block;
		margin-bottom: 1rem;
	}
}